<template>
  <v-container>
    <div class="d-flex justify-center justify-md-start">
      <span class="text-h4 text-sm-h3 "><b>Mis invitaciones</b></span>
    </div>
    <v-toolbar-title class="text-h5 text-wrap mt-10" style="color: #313945;"></v-toolbar-title>
    <v-row>
      <ChatComponent />
      <!-- Card -->
      <v-col cols="12" sm="12" md="7" v-if="ListInvitaciones.length != 0">
        <!-- Si hay data -->
        <template>
          <v-card
              class="mx-auto mt-10"
              max-width="844"
              v-for="(item, index) in [...ListInvitaciones]" :key="index"
              style="border-radius: 20px;"
              :style="{ backgroundColor: getBg() }"
          >
            <v-card-text>
              <p class="text-h7 text-sm-h6 text--primary">
                {{
                  item.oferta.solicitud?.UnidadOrganizativa?.nombre ? item.oferta.solicitud?.UnidadOrganizativa?.nombre : 'sin nombre'
                }}
              </p>
              <div class="text-h6 text-sm-h5 text--primary font-weight-bold">
                {{ item.oferta.solicitud?.titulo_oferta ? item.oferta.solicitud?.titulo_oferta : 'sin plaza' }}
              </div>
            </v-card-text>
            <v-card-actions
                :style="$vuetify.breakpoint.smAndUp ? 'display: flex; flex-direction: row-reverse' : 'display: flex; flex-direction: column'">
              <v-btn
                  depressed
                  color="#313945"
                  rounded
                  class="mt-3 font-weight-bold text-none"
                  dark
                  @click="verEmpleoDetalle(item.id_oferta)"
              >
                Ver empleo
              </v-btn>
              <v-btn
                  v-if="item.id_estado_oferta_persona === null  && item.id_tipo_aplicacion === 2 "
                  depressed
                  rounded
                  class="mr-5 mt-3 font-weight-bold text-none"
                  style="border: 1px solid; border-color:#7c788a;"
                  @click.stop="modalRechazar(item)"
              >
                Rechazar invitación
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
      <v-col v-else>
        <v-row>
          <v-col class="d-flex flex-column justify-center align-center">
            <v-img style="width: 500px;" src="../../assets/img/NoInvitacionesRRHH.png">
            </v-img>
            <h1 class="mt-5 text-primary-color text-center text-h5 font-weight-black">Aún no tienes invitaciones
              disponibles</h1>
          </v-col>

        </v-row>
      </v-col>

      <!-- Imagen -->
      <v-col cols="12" sm="12" md="5" class="d-flex justify-center align-center" v-if="ListInvitaciones.length != 0">
        <v-card class="d-flex flex-column justify-center align-center" flat
                style="border-radius: 20px;">
          <v-img
              src="../../assets/img/imglaboral2.svg"
          />
          <p class="text-h6 text--primary font-weight-bold text-center">
            Mira tus invitaciones a ofertas de empleos
          </p>
        </v-card>
      </v-col>

      <!-- Modal -->
      <v-dialog
          v-model="dialog"
          max-width="490"
      >
        <v-card class="d-flex flex-column justify-center align-center" height="250" style="border-radius: 20px;">
          <v-card-title class="text-h5 text-center" style="word-break: normal ">
            ¿Estas seguro de rechazar <br> esta oferta de empleo?
          </v-card-title>

          <!-- <v-card-text>

          </v-card-text> -->

          <v-card-actions class="d-flex justify-center align-center">
            <v-spacer></v-spacer>

            <v-btn
                depressed
                rounded
                class="mr-5 font-weight-bold text-none"
                style="border: 1px solid; border-color:#7c788a;"
                @click="dialog = false"
            >
              Cerrar
            </v-btn>

            <v-btn
                depressed
                color="#313945"
                rounded
                class="font-weight-bold text-none"
                dark
                @click="rechazarInvitacion()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
    <app-loader v-if="loading"></app-loader>
  </v-container>
</template>

<script>
import AppLoader from "@/components/AppLoader.vue";
import ChatComponent from "../../components/ChatComponent.vue";
import {mapState} from "vuex";

export default {
  name: "mi-invitacion",
  components: {AppLoader, ChatComponent},
  data: () => ({
    ListInvitaciones: [],
    bgCards: [
      '#f6f6f6',
      '#d2d2db',
      '#e3d3c9',
    ],
    itemRechazar: null,
    invitaciones: [{
      id: 1,
      nombre_institucion: "Secretaria de Innovación",
      plaza: "Diseñador Senior UI/UX",
      color: "#f6f6f6",
      estado: 1,
      nombre_estado: "pendiente"
    }, {
      id: 2,
      nombre_institucion: "Secretaria de Innovación",
      plaza: "Diseñador Senior UI/UX",
      color: "#d2d2db",
      estado: 2,
      nombre_estado: "Aceptada"
    }, {
      id: 3,
      nombre_institucion: "Secretaria de Innovación",
      plaza: "Diseñador Senior UI/UX",
      color: "#e3d3c9",
      estado: 3,
      nombre_estado: "Rechazada"
    }],
    // invitaciones: [],
    dialog: false,
    loading: false,
  }),

  methods: {

    modalRechazar(item) {
      this.dialog = true
      this.itemRechazar = item
    },

    async verInvitacion(id) {
      //console.log('se acepto', id);
    },

    verEmpleoDetalle(item) {
      let id = item
      this.$router.push(`/empleo/${id}`)
    },

    async rechazarInvitacion() {
      this.loading = true;
      let id = this.itemRechazar.id
      if (this.isTokenValido(this.token)) {
        // if(this.tieneRoles(this.token)) {
        try {
          const response = await this.services.invitaciones.putInvitacion(id)
          if (response.status == 200) {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
            await this.getListadoInvitaciones();
            this.dialog = false
          }
        } catch (e) {

        } finally {
          this.loading = false;
        }

        // } else {
        //   this.temporalAlert({
        //     show:true,
        //     type:"error",
        //     message:"No tiene permiso para rechazar esta invitación"
        //   })
        //   this.dialog = false
        // }

      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }

    },

    getBg() {
      return this.bgCards[Math.floor(Math.random() * this.bgCards.length)];
    },

    async getListadoInvitaciones() {
      if (this.isTokenValido(this.token)) {
        // if(this.tieneRoles(this.token)) {
        try {
          if(typeof this.userInfo.id_persona !== 'undefined'){
            const response = await this.services.invitaciones.getInvitaciones(this.userInfo.id_persona)
            if (response.status == 200) {
              this.ListInvitaciones = response.data;
              //console.log("datos",this.ListInvitaciones)
            }
          }
        } catch (e) {

        } finally {

        }

        // } else {
        //   this.temporalAlert({
        //     show:true,
        //     type:"error",
        //     message:"No tiene permiso para listar sus invitaciones"
        //   })
        // }

      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "token"]),
  },
  created() {

    this.loading = true;
    setTimeout(async () => {
      await this.getListadoInvitaciones();
      this.loading = false;
    }, 2000);

  },
};
</script>

<style scoped>

</style>
